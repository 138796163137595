// var dateFormat = require('dateformat');
var moment = require("moment");

export const objectsConstants = {
	USER: {
		name: '',
		email: '',
		username:'',
		password: '',
		confirm_password: '',
		roles: [],
		image: '',
		is_active: false
	},

	AGENCYUSER: {
		name: '',
		email: '',
		username: '',
		fax: '',
		phone1: '',
		phone2: '',
		password: '',
		confirm_password: '',
		is_active:true,
		agencyRoles: []
	},
	PERMISSION: {
		name: '',
		permission_type: '',
		is_menu: '',
		is_new_tab: false,
		label: '',
		icon: '',
		description: '',
		parent_label: '',
		order: '',
		path: '',
		module_id: ''
	},
    LOGINERROR:'',
	AGENCY: {
		id:'',
		name: '',
		publishable_agency_name: '',
		agency_type_id: '',
		website: 'https://',
		created_at:'',
		custom_template: 0,
		sales_rep_id: '',
		//sleeve_inventory: 0,
		//disc_inventory: 0,
		proshow_file_company_name: '',
		program_type_id: '',
		free_dvd_to_agency: 0,
        tax_exempt: 0,
		free_dvd_to_client: 1,
		invoice_style_id: '',
		brand_id: '',
		agency_resources:[],
		primary_contacts:{name:'', email:'', contact:'', password:'', confirm_password:'', username:'', phone1: ''},
		settings: {
			disable_minimum_price: { 'value': 0 },
			second_address_to_copies: { 'value': 0 },
			do_not_call_customer: { 'value': 0 },
			auto_approve_photos: { 'value': 0 },
			disable_auto_approve: { 'value': 0 },
			show_logo_file_on_front_end: { 'value': 0 },
			generate_photo_zips: { 'value': 0 },
			keep_high_resolution: { 'value': 0 },
			disable_social_media_sharing: { 'value': 0 },
			allow_mapping_messaging: { 'value': 0 },
			allow_traveler_to_use_map: { 'value': 0 },
			allow_traveler_to_see_all_on_trip: { 'value': 0 },
			hosts_images: { 'value': 0 },
			collect_tax: { 'value': 0 },
			free_fash_drive_to_client: { 'value': 0 },
			no_gl_copy: { 'value': 0 },
			// accepts_wt_payments: { 'value': 0},
			// wt_show_on_all_orders: { 'value': 0},
			// wt_option_at_order_placement: { 'value': 0},
			// wt_link: { 'value': ''},
			send_gl_fd_copy: { 'value': 1 },
			allow_preawareness_flyer_generation: { value: 0 },
			invoice_period:{value:0},
		},
		account_comments: '',
		mosaic_letter_text: '',
		transmittal_letter_text: '',
		primary_address: {
			street_address_1: '', street_address_2: '', city: '', zipcode: '', state_id: '', country_id: '', state: { title: '' }
		},
		secondary_address: {
			street_address_1: '', street_address_2: '', city: '', zipcode: '', state_id: '', country_id: ''
		},
		accounting_contacts: {
			name: '',
			phone1: '',
			//fax: '',
			email: ''
		},
		collapseAccountingContact: false,
		collapseWebMasterContact: false,
		collapseAgencySettings: false,
		web_master_contacts: {
			name: '',
			email: ''
		},
		accounts: [],
		Inventories: {
			Disc: {
				quantity: 0,
				item_id: 0,
				itemParent: 'Disc',
				agency_id: 0,
				threshold: 0,
				purchased_price: 0,
				id: 0,
			},
			Sleeve: {
				quantity: 0,
				item_id: 0,
				itemParent: 'Sleeve',
				agency_id: 0,
				threshold: 0,
				purchased_price: 0,
				id: 0,
			},
		}
	},
	MOREBUTTONS: {
		title: '',
		description: '',
		file_path: '',
		sort_order: '',
		link: '',
		model_id: null,
		order_id: null,
		is_wetravel: 0,
		show_on_all_orders: 0,
		option_at_order_placement: 0,
		button_code: '',
	},
	ADVANCESEARCH: {
		agency_id: '',
        account_id: [],
		barcode: '',
		contact_name: '',
		destination: '',
		contact_phone: '',
		contact_email: '',
		username_password: '',
		address: '',
		country: '',
		state: '',
		status_id:'',
		departure_date: {
			value:'',
			type:'date',
		},
		return_date: {
			value:'',
			type:'date',
		},
		pre_trip_package_need_date: {
			value:'',
			type:'date',
		},
		video_special_need_date: {
			value:'',
			type:'date',
		},
		after_or_equal_departure_date:{
			value:'',
			type:'date',
		},
		before_or_equal_departure_date: {
			value:'',
			type:'date'
		},
		program_type: '',
	},
	ADITIONALFILTERS: {
		on_hold: false,
		un_resolved: false,
		cancelled: false,
		not_cancelled: false,
		produced: false,
		not_produced: false,
		auto_approve_photos: false,
		not_auto_approve_photos: false,
		digital_assets_assigned: false,
		reorders: false,
		orderStatus: '',
		not_programType:true,
	},
	USERPREFERENCES: {
		"agency": {
			"name": true
		},
		"agency_sales_rep":false,
		"gtv_sales_rep": false,
		"going_on_trip_count": false,
		"following_from_home_count": false,
		"group_name": true,
		"group_leader_contact": {
			"name": true,
			"phone1": false,
			"phone1_ext": false,
			"phone2": false,
			"phone2_ext": false,
			"email": false
		},
		"destinations": true,
		"client_tour_number": false,
		"client_tour_code": false,
		"agency_type": {
			"title": false
		},
		"pre_trip_material_address": {
			"street_address_1": false,
			"street_address_2": false,
			"city": false,
			"state_id": false,
			"country_id": false,
			"zipcode": false,
			"country": false,
			"state": false
		},
		"video_shipping_address": {
			"street_address_1": false,
			"street_address_2": false,
			"city": false,
			"state_id": false,
			"country_id": false,
			"zipcode": false,
			"country": false,
			"state": false
		},
		"created_at": true,
		"order_dates": {
			"departure_date": {
				"slug": false,
				"title": false,
				"value": true
			},
			"return_date": {
				"slug": false,
				"title": false,
				"value": false
			},
			"last_day_of_school": {
				"slug": false,
				"title": false,
				"value": false
			},
			"pre_trip_package_need_date": {
				"slug": false,
				"title": false,
				"value": false
			},
			"video_special_need_date": {
				"slug": false,
				"title": false,
				"value": false
			},
			"upload_cut_off_date": {
				"slug": false,
				"title": false,
				"value": false
			},
			"receive_date": {
				"slug": false,
				"title": false,
				"value": true
			},
		},
		"photo_admin_username": false,
		"photo_admin_password": false,
		"order_totals": {
			"total": false
		},
		"program_type": {
			"title": false
		},
		"order_shipping_camera" : {
			"tracking_number":false,
			"shipped_date":true
		},
		"order_shipping_video" : {
			"created_at":false
		},
		"settings": {
			"photos_submitted_for_video_production": {
				"value": false
			},
			"finalize_by_qa" : {
				"value": true
			},
			"online_options_page_submitted" : {
				"value": false
			},
			"full_video_view_count" : {
				"value": false
			},
			"full_video_download_count" : {
				"value": false
			},
			"highlight_video_view_count" : {
				"value": false
			},
			"highlight_video_download_count" : {
				"value": false
			}
		},
		"numberOfVideos": true,
		"numberOfMozaix": true,
		"quick_login": false,
		"originalImages": false,
		"inventory_assignment":false,
		"edit_produced_by": true,
		"production_status": true,
	},
	AGENCYADVANCESEARCH: {
		agency_name: '',
        agency_website: '',
        account_id: [],
        primary_contact_name: '',
        primary_contact_number: '',
        primary_contact_email: '',
        agency_type: '',
		program_type: '',
        gtv_sales_rep: '',
	},
	AGENCYUSERPREFERENCES: {
		"name": true,
		"website":true,
		"primary_contacts": {
			"name": true,
			"phone1": true,
			"phone1_ext": false,
			"phone2": false,
			"phone2_ext": false,
			"email": true
		},
        "program_type": {
			"title": true
		},
        "quick_link_to_agency_profile": true,
        "brand": false,
		"agency_type": {
			"title": true
		},
        "publishable_agency_name": true,
        "accounting_contact": true,
		"gtv_sales_rep": false,
		"primary_address": {
			"street_address_1": false,
			"street_address_2": false,
			"city": false,
			"state_id": false,
			"country_id": false,
			"zipcode": false,
			"country": false,
			"state": false
		},
		"created_at": true,
        "users": false,
        "agency_all_users_name": false,
        // "agency_all_users_email": false,
        "keep_high_resolution_photos": false,
	},
	ORDERDETAIL: {
		barcode: '',
		on_hold: '0',
		order_number: '',
		group_name: '',
		agency_id: null,
		going_on_trip_count:0,
		following_from_home_count:0,
		customer_slates_for_videos_id: null,
		agency: {
			id: '',
			name: '',
		},
		group_leader_contact: {
			name: '',
			phone1: '',
			phone1_ext: '',
			phone2: '',
			phone2_ext: '',
			email: '',
		},
		group_leader_contacts: [
			{
				name: '',
				phone1: '',
				phone1_ext: '',
				phone2: '',
				phone2_ext: '',
				email: '',
				type:'Primary'
			}
		],
		agency_type_id: '',
		agency_sales_rep: {
			id: '',
			name: '',
			email: '',
		},
		gtv_sales_rep: {
			id: '',
			name: '',
			email: '',
		},
		agency_type: {
			id: '',
			title: '',
		},
		agency_sales_rep_id: '',
		status: {
			id: '',
			title: '',
		},
		gtv_sales_rep_id: '',
		destinations: [],
		order_dates: {
			departure_date: {
				value: '',
				// value: new Date(),
			},
			return_date: {
				value: '',
				// value: new Date(),
			},
			last_day_of_school: {
				// value: dateFormat(new Date(), "yyyy-mm-dd"),
				value: '',
			},
			pre_trip_package_need_date: {
				// value: dateFormat(new Date(), "yyyy-mm-dd"),
				value: '',
			},
			video_special_need_date: {
				// value: dateFormat(new Date(), "yyyy-mm-dd"),
				value: '',
			},
			receive_date: {
				// value: new Date(),
				value: '',
			},
			upload_cut_off_date: {
				// value: dateFormat(new Date(), "yyyy-mm-dd"),
				value: new Date(),
			},
			finish_by_date: {
				// value: dateFormat(new Date(), "yyyy-mm-dd"),
				value: '',
			},
		},
		client_tour_code: '',
		client_tour_number: 0,
		traveler_link: '',
		photo_admin_link: '',
		settings: {
			photos_submitted_for_video_production: {
				value: ''
			},
			on_hold: {
				value: '0'
			},
			received_client_equipment: {
				value: '0'
			},
			return_package_not_received: {
				value: '0'
			},
			client_equipment_comments: {
				value: ''
			},
			non_received_return_package_comments: {
				value: ''
			},
			non_uploaded_photos_received: {
				value: 0
			},
			approved_for_upload: {
				value: 0
			},
			finalize_by_qa: {
				value: 0
			},
			finalize_by_editor: {
				value: 0
			},
			finalize_by_pm: {
				value: 0
			},
			no_gl_copy: {
				value: 0
			},
			send_gl_fd_copy: {
				value: 0
			},
			photos_renamed: {
				value: 0
			},
			best_time_to_call : {
				value: ''
			}
		},
		program_type: {
			item_category: {}
		},
		order_shipping_items: [],
		music_selection_criteria: '',
		uuid:'',
	},
	PRICINGDETAIL: {
		program_type_id: '',
		free_additional_dvds_to_agency: 0,
		free_additional_dvds_to_client: 0,
		number_of_cameras:0,
		number_of_flashdrive:1,
		number_of_sdcard:1,
		discount_type: "$",
		discount_value: 0,
		taxable: false,
		order_items: {
		},
		order_totals: {
			production: {
				code: 'production',
				value: 0,
				title: 'normal_production'
			},
			overnight:{
				code: 'overnight',
				value: 0,
				title: 'overnight_production'
			}
		},
		settings: {
			no_gl_copy: { 'value': 0 },
			send_gl_fd_copy: { 'value': 0 },
			free_fash_drive_to_client: {  'value':0 },
		},
	},
	SHIPPINGDETAIL: {
		pre_trip_material_address: {
			street_address_1: '',
			street_address_2: '',
			city: '',
			state_id: '',
			state: '',
			country_id: '',
			country: '',
			zipcode: '',
		},
		video_shipping_address: {
			street_address_1: '',
			street_address_2: '',
			city: '',
			state_id: '',
			state: '',
			country_id: '',
			country: '',
			zipcode: '',
		},
		camera_ship_status: null,
		video_ship_status: null,
		mosaic_ship_status: null,
		assignedItems:[],
	},

	ADDCOMMENT: {
		to_gl_comment: ''
	},

	RANDOMCOLORS: [
		'#95c581',
		'#fd7295',
		'#9b6ab4',
		'#5ec9c8',
		'#fe9e55',
		'#ad84bf',
		'#ffb58b',
		'#88ce56',
		'#ff7994',
		'#a368b9'
	],

	DEFAULTCOUNTRY: "United States",
	MINORDER: 150,

	INVENTORY: {
		item_id: 0,
		agency_id: 0,
		threshold: 0,
		purchased_price: 0,
		quantity: 0,
		serial_number: '',
		barcode: '',
		badgeInventory: false,
		isSerialNumber: false,
	},
	ITEM: {
		title: '',
		item_category_id: null,
		show_on_order_form: false,
		item_type_id: null,
		price: 0,
		taxable: false,
		dvd: 0,
	},
	ITEMADDITIONALSERVICE:{
		title: '',
		label:'',
		item_id: '',
		quantity: 1,
		price: 0
	},
	COUNTRIESNOTREQZIP: [
		'Bahamas'
	],
	DEFAULTAGENCY: "Group Travel Videos",
	DEFAULTBRAND: "GTV",
	DEFAULTGROUPTYPE: "Student Travel",
	DESTINATION: {
		title: '',
	},
	flagged_ordres: [],
	multiple_shipment_orders: [],
	PROSHOWFILE: {
		title: '',
		agency_types: [],
		agencies: [],
		brands: [],
		destinations: [],
		range_id: '',
		file: '',
		isDefault: false,
		is_active: true
	},
	BRAND: {
		title: '',
		domain_slug: '',
		resource_file: '',
	},
	CONTACTLOGDAYS: [
		{ value: 7, label: 'Orders Shipping out by 7 days ' + moment().add(7, 'days').format('DD-MM-YYYY') },
		{ value: 17, label: 'Orders Shipping out by 17 days ' + moment().add(17, 'days').format('DD-MM-YYYY') },
		{ value: 25, label: 'Orders Shipping out by 25 days ' + moment().add(25, 'days').format('DD-MM-YYYY') },
		{ value: 29, label: 'Orders Shipping out by 29 days ' + moment().add(29, 'days').format('DD-MM-YYYY') }
	],

	UPLOADPHOTOSFORVIDEOPRORECEIVEDAYS: [
		{ value: 10, label: 'last 10 days'},
		{ value: 30, label: 'last 30 days' },
		{ value: 183, label: 'last 6 month' },
	],
	UPLOADPHOTOSFORVIDEOPRODAYS: [
		// { value: 0, label: 'Select no of days'},
		{ value: 'Previous Week', label: 'Previous Week'},
		{ value: 'Current Week', label: 'Current Week'},
		{ value: 10, label: 'last 10 days'},
		{ value: 30, label: 'last 30 days' },
		{ value: 183, label: 'last 6 month' },
	],
	UCDREACHEDDAYS: [
		{ value: 30, label: 'Report 30 Days Back' },
		{ value: 60, label: 'Report 60 Days Back' },
		{ value: 90, label: 'Report 90 Days Back' },
		{ value: 180, label: 'Report 180 Days Back'},
		{ value: 365, label: 'Report 365 Days Back'},
		{ value: 0, label: 'Report For Whole List'}
	],
	FAQ: {
		question: '',
		answer: '',
		// category_id:'',
		account_id:'',
		is_web: 1,
	},
	TRANSMITTALTEMPLATE: {
		subject : '',
		question : '',
		description : '',
		transmittal_category_id : '',
		transmittal_type_id : '',
		account_id: '',
	},
	APPS : [
		{
			title:'Group Travel Videos',
			domain_slug:'gtv',
			android:'https://play.google.com/store/apps/details?id=com.PhotoVisionInc.GTV',
			ios:'https://apps.apple.com/us/app/group-travel-videos/id581218198',
		},
		{
			title:'Incentive TripKit',
			domain_slug:'itk',
			android:'https://play.google.com/store/apps/details?id=com.incentive.tripkit',
			ios:'https://apps.apple.com/us/app/incentive-tripkit/id1478895852',
		},
		{
			title:'Music Celebrations International/Faith Journey',
			domain_slug:'mci',
			android:'https://play.google.com/store/apps/details?id=com.photovision.mci',
			ios:'https://apps.apple.com/us/app/travel-toolkit/id1459232411',
		},
		{
			title:'Educational Tours Inc.',
			domain_slug:'eti',
			android:'https://play.google.com/store/apps/details?id=com.photovision.pro.eti',
			ios:'https://apps.apple.com/us/app/educational-tours/id1446418577',
		},
		{
			title:'Bob Rogers Travel',
			domain_slug:'brt',
			android:'https://play.google.com/store/apps/details?id=com.bobrogertravel',
			ios:'https://apps.apple.com/us/app/bob-rogers-travel/id1279818727',
		},
		{
			title:'Ambassador Of Music',
			domain_slug:'aom',
			android:'https://play.google.com/store/apps/details?id=com.photovisioninc.aom',
			ios:'https://apps.apple.com/us/app/ambassador-of-music/id6447245790',
		}
	],
	BUGSNAGKEY : 'd34db7aa6631feaa3cf12c468063c572',
	EXCEPTITEMS : [
		'Group Photo',
		'11x14 MOSAICS',
		'ADDITIONAL CAMS/CARDS',
	],

	EXCEPTITEMSLPR : [
		'Group Photo',
		'Custom Editing Hrly Charge',
        'CD of Images',
        'Individual Shipping of DVDs',
        'Individual Shipping of DVDs Teacher Special',
		'ADDITIONAL CAMS/CARDS',
		'Videos/Travelers',
		'20x30 PhotoMozaix',
	],
	NEWEVENT: {
        start: null,
        end: null,
        title: '',
        name: '',
        email: '',
        desc: '',
        phone:'',
    },
	WETRAVELTITLE : 'Accepts WeTravel Payments?',
	WETRAVELDESCRIPTION : 'Make a payment towards your trip',

    defauldFromAddress : {
        // -------------------- Production Address --------------------
        id: 116376,
        model_type: "App\\Account",
        model_id: 1,
        address_type_id: 5,
        street_address_1: "8300 Fairview Road",
        street_address_2: null,
        city: "Mint Hill",
        state_id: 34,
        country_id: 1,
        zipcode: "28227",
        csv_value: "8300 Fairview Road Mint Hill United States North Carolina NC 28227",
        full_address: "8300 Fairview Road \n                    <br />Mint Hill, North Carolina 28227\n                    <br />United States",
        account: {
            id: 1,
            domain_slug: "gtv",
            title: "Group Travel Videos"
        },
        country: {
            id: 1,
            title: "United States",
            code: "USA"
        },
        state: {
            id: 34,
            code: "NC",
            title: "North Carolina"
        }
        // -------------------- Staging Address --------------------
        // id: 2051,
        // model_type: "App\\Account",
        // model_id: 1,
        // address_type_id: 5,
        // street_address_1: "8300 Fairview Road",
        // street_address_2: null,
        // city: "Mint Hill",
        // state_id: 133,
        // country_id: 7,
        // zipcode: "28227",
        // csv_value: "8300 Fairview Road Mint Hill United States North Carolina NC 28227",
        // full_address: "8300 Fairview Road \n                    <br />Mint Hill, North Carolina 28227\n                    <br />United States",
        // account: {
        //     id: 1,
        //     domain_slug: "gtv",
        //     title: "Group Travel Videos"
        // },
        // country: {
        //     id: 7,
        //     title: "United States",
        //     code: "US"
        // },
        // state: {
        //     id: 133,
        //     code: "NC",
        //     title: "North Carolina"
        // }
    }
};
