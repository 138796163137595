import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Grid,
    Row,
    Col,
    OverlayTrigger,
    Popover,
    Button
} from "react-bootstrap";
import Select from 'react-select';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Card} from "components/Card/Card.jsx";
import {hasPermission} from 'helper/hasPermission';
import { getBrands } from "redux/actions/account-action";
import {
    updateStateVariableDynamic,
    intersectionOfObjectsWithNewValue,
    returnColumnValueWithColumnMatch,
    returnDollars,
    returnDynamicURL,hasRole,dateFormatMy
} from 'helper/helperFunctions';
import {deleteOrder, saveSearchParams, getOrdersPagination} from 'redux/actions/order-actions';
import {
    getUserPreferences,
    storeOrUpdateUserPreferences,
    getCountries,
    getStates,
    getMainProgramType,
    getOrderStatuses,
    getDefaultPreference,
    storeAdvanceSearch
} from 'redux/actions/settings-actions';
import {FormInputs} from "components/FormInputs/FormInputs.jsx";
import {Link} from 'react-router-dom';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import {objectsConstants as OC} from 'redux/constant/objects-constant';
import {adminLabels} from 'redux/constant/admin-label-constant';
import UserPreferences from 'components/Admin/Order/UserPreferences';
import AdditionalSearch from 'components/Admin/Order/AdditionalSearch';
import AdvanceSearchForm from 'components/Admin/Order/AdvanceSearchForm';
import {getAllAgencies, getAllAgenciesPublishable} from 'redux/actions/agency-actions';
import {login} from 'redux/actions/front-end-action.jsx';
import {orderService} from 'services/order';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import OrderListPagination from '../../ThemeComponents/OrderListPagination';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';

var Modal = require('react-bootstrap-modal');
var cloneDeep = require('lodash.clonedeep');

const setAllTo = (val, object) => Object.keys(object).reduce((prev, curr) => ({
    ...prev,
    [curr]: typeof object[curr] === 'object' ?
        setAllTo(val, object[curr]) : val
}), {});
const DEFAULTBRANDS = [
    {
        description : "GTV APP CARDS",
        domain_slug : "gtv",
        is_master : 0,
        label : "GTV",
        title : "Group Travel Videos",
        value :  1
    },
    {
        description : "ETI APP CARDS",
        domain_slug : "eti",
        is_master : 0,
        label : "ETI",
        title : "Educational Tours Inc.",
        value :  4
    },
    {
        description : "BRT APP CARDS",
        domain_slug : "brt",
        is_master : 0,
        label : "BRT",
        title : "Bob Rogers Travel",
        value :  2
    },
    {
        description : "ITK APP CARDS",
        domain_slug : "itk",
        is_master : 0,
        label : "itk",
        title : "Incentive TripKit",
        value :  6
    }

]
class OrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            advanceSearchModal: this.props.Dashboard ? false : true,
            // advanceSearchModal: false,
            filtered: '',
            // search: {
            //   advanceSearch: this.props.searchParams,
            //   additionalFilters: { ...OC.ADITIONALFILTERS },
            //   userPreferences: { ...OC.USERPREFERENCES },
            // },
            search: this.props.searchParams,
            newSearch: {
                searchName: '',
                is_default: false,
            },
            options: {
                states: [],
            },
            collapse: {
                isOpenAdvanceSearch: true,
                isOpenAdvanceFilters: true,
                isOpenUserPreferences: true,
                isOpenSaveSearch: false,
            },
            defaultSearchId: '',
            advanceSearchAction: this.props.Dashboard ? '' : 'update',
            // advanceSearchAction: '',
            showAll: false,
        };

        this.ordersPaginationData = this.ordersPaginationData.bind(this);
        this.filterSearchHandle = this.filterSearchHandle.bind(this);

        this.deleteConfirmOrder = this.deleteConfirmOrder.bind(this);

        this.openAdvanceSearchModal = this.openAdvanceSearchModal.bind(this);
        this.closeAdvanceSearchModal = this.closeAdvanceSearchModal.bind(this);

        this.handleSearchCheckBox = this.handleSearchCheckBox.bind(this);
        this.changeStatusForSearch = this.changeStatusForSearch.bind(this);

        this.handleAdvanceSearch = this.handleAdvanceSearch.bind(this);
        this.handleChangeAdvanceSearchDropDown = this.handleChangeAdvanceSearchDropDown.bind(this);
        this.onChangsAdvanceSearchDate = this.onChangsAdvanceSearchDate.bind(this);

        this.submitUserPreferences = this.submitUserPreferences.bind(this);


        this.performSearch = this.performSearch.bind(this);
        this.clearAdvanceSearchForm = this.clearAdvanceSearchForm.bind(this);

        this.collapseHandle = this.collapseHandle.bind(this);


    };

    collapseHandle(field) {
        this.setState(prevState => ({
            ...prevState, collapse: {
                ...prevState.collapse, [field]: !this.state.collapse[field]
            }
        }));
    }

    componentDidMount() {

        this.props.getUserPreferencesCall().then( response => {
            // console.log("user preferences", response);
            if (response.UserSearches[0].preferences){
                localStorage.setItem('searchParams', JSON.stringify(response.UserSearches[0].preferences));
            }
        });
        this.props.getAgenciesCall();
        // this.props.getAllAgenciesPublishableCall();
        this.props.getCountriesCall();
        this.props.getMainProgramTypeCall();
        this.props.getOrderStatuses()
        this.props.getDefaultPreferenceCall();
        this.props.getBrandsCall();


        if (!hasRole(['gm admin'])) {
            if (localStorage.getItem('searchParams')) {
                const search = JSON.parse(localStorage.getItem('searchParams'));
                this.setState({
                    search: search
                })
            }
            const advanceSearch = cloneDeep(this.state.search.advanceSearch);
            const advanceSearchDropDown = {...this.state.search.advanceSearch};
                if (Array.isArray(DEFAULTBRANDS)) {
                        DEFAULTBRANDS.forEach(option => {
                            if (!advanceSearchDropDown.account_id.includes(option)) {
                                advanceSearch['account_id'].push(option);
                            }
                        })
                }
            this.setState(prevState => ({
                ...prevState, search: {
                    ...prevState.search, advanceSearch: advanceSearch
                }
            }), () => {
                this.props.saveSearchParams(this.state.search);
                localStorage.setItem('searchParams', JSON.stringify(this.state.search))
            });
        }
    }

    clearBrands() {
        localStorage.removeItem('searchParams');
        const advanceSearch = {...OC.ADVANCESEARCH};
        advanceSearch['account_id'] = [];
        this.setState(prevState => ({
            ...prevState, search: {
                ...prevState.search, advanceSearch: advanceSearch
            }
        }), () => {
            this.props.saveSearchParams(this.state.search);
        });

    }
    clearAdvanceSearchForm() {
        this.clearBrands();
        localStorage.removeItem('searchParams');
            this.setState(prevState => ({
                ...prevState,
                search: {
                    ...prevState.search,
                    advanceSearch: OC.ADVANCESEARCH,
                    additionalFilters: OC.ADITIONALFILTERS,
                    userPreferences: OC.USERPREFERENCES
                }
            }), () => {
                this.props.saveSearchParams(this.state.search);
            });

    }

    performSearch() {
        if (Object.keys(this.state.search.advanceSearch.account_id).length > 0 && (this.state.search.advanceSearch.barcode !== "" || this.state.search.advanceSearch.agency_id !== "" && this.state.search.advanceSearch.agency_id !== null))
            {
                const advanceSearch = cloneDeep(this.state.search.advanceSearch);
                advanceSearch['account_id'] = [];
                this.setState(prevState => ({
                    ...prevState, search: {
                        ...prevState.search, advanceSearch: advanceSearch
                    }
                }), () => {
                    this.props.saveSearchParams(this.state.search);
                });
            }
        this.setState({advanceSearchModal: false}, function () {
            // console.log(this.pagination.reactTable);

            // let paginationState = this.pagination.reactTable.state;
            // paginationState.page = 0;
            // console.log('uuuu', paginationState);
            // this.pagination.reactTable.state.onFetchData(paginationState);
            // this.pagination.reactTable.onFetchData()
            this.pagination.dataCall(0);
            // this.props.saveSearchParams(this.state.search);
        });
    }

    submitUserPreferences(searchId) {
        var preferencesData = {
            searchName: this.state.newSearch.searchName,
            is_default: this.state.newSearch.is_default,
            search: {...this.state.search}
        };
        this.props.storeOrUpdateUserPreferencesCall(preferencesData, searchId).then(
            response => {
                // console.log('response', response);
                this.props.getUserPreferencesCall();
                if (response.data.is_default) {
                    this.props.getDefaultPreferenceCall();
                }
                this.setState({
                    search: response.data.preferences,
                    defaultSearchId: response.data.id,
                    newSearch: {...this.state.newSearch, is_default: response.data.is_default}
                }, () => {
                    this.pagination.dataCall();

                    this.setState({advanceSearchModal: false});
                });
            },
            error => {

            }
        );
    }

    onChangsAdvanceSearchDate(value, field, dateType) {
        const advanceSearchState = cloneDeep(this.state.search.advanceSearch);
        advanceSearchState[field]['value'] = value !== null ? value : '';
        advanceSearchState[field]['type'] = dateType;
        this.setState(prevState => ({
            ...prevState, search: {
                ...prevState.search, advanceSearch: advanceSearchState
            }
        }), () => {
            this.props.saveSearchParams(this.state.search);
            localStorage.setItem('searchParams', JSON.stringify(this.state.search))
        });
    }

    handleChangeBrand = (selectedOptionType, info) => {
        console.log("selected option type", selectedOptionType);
        if (info.name === 'account_id') {
            const advanceSearchDropDown = {...this.state.search.advanceSearch};
            const advanceSearch = cloneDeep(this.state.search.advanceSearch);
            if (Array.isArray(selectedOptionType)) {
                if (info.action === 'select-option') {
                    selectedOptionType.forEach(option => {
                    console.log("advanceSearch.account_id", advanceSearch.account_id);
                    console.log("this.staye.search", this.state.search.advanceSearch.account_id);
                        if (!advanceSearchDropDown.account_id.includes(option)) {
                            console.log("execute", option);
                            advanceSearch[info.name].push(option);
                        }
                    });
                } else if (info.action === 'remove-value') {
                    advanceSearch[info.name] = advanceSearch[info.name].filter(item => {
                        return item.value !== info.removedValue.value
                    })
                }
                // this.setState(prevState => ({
                //     ...prevState, search: {
                //         ...prevState.search, advanceSearch: advanceSearch
                //     }
                // }), () => {
                //     this.props.saveSearchParams(this.state.search);
                //     localStorage.setItem('searchParams', JSON.stringify(this.state.search))
                // });
            } else {
                if (info.action === 'remove-value') {
                    advanceSearch[info.name] = advanceSearch[info.name].filter(item => {
                        return item.value !== info.removedValue.value
                    })
                } else if (info.action === 'clear') {
                    advanceSearch[info.name] = [];
                } else {
                    advanceSearch[info.name] = selectedOptionType.value;
                }
            }
            this.setState(prevState => ({
                ...prevState, search: {
                    ...prevState.search, advanceSearch: advanceSearch
                }
            }), () => {
                this.props.saveSearchParams(this.state.search);
                localStorage.setItem('searchParams', JSON.stringify(this.state.search))
            });
        }
	}

    handleChangeAdvanceSearchDropDown(selectedOptionType, info) {
        if (info['action'] === 'select-option') {
            const advanceSearchDropDown = {...this.state.search.advanceSearch};
            advanceSearchDropDown[info['name']] = selectedOptionType['value'];
            this.setState(prevState => ({
                ...prevState, search: {
                    ...prevState.search, advanceSearch: advanceSearchDropDown
                }
            }), function () {
                if (info['name'] === 'country') {
                    this.props.getStateCall(selectedOptionType['value'], 'orderList');
                }
                this.props.saveSearchParams(this.state.search);
                localStorage.setItem('searchParams', JSON.stringify(this.state.search))
            });
        } else if (info['action'] === 'clear') {
            const advanceSearchClear = {...this.state.search.advanceSearch};
            advanceSearchClear[info['name']] = null;
            if (info['name'] === 'country') {
                advanceSearchClear['state'] = null;
            }
            this.setState(prevState => ({
                ...prevState, search: {
                    ...prevState.search, advanceSearch: advanceSearchClear
                }
            }), () => {
                this.props.saveSearchParams(this.state.search);
                localStorage.setItem('searchParams', JSON.stringify(this.state.search))
            });
        }
    }

    handleAdvanceSearch(e) {
        const {name, value} = e.target;
        const advanceSearchState = {...this.state.search.advanceSearch};
        advanceSearchState[name] = value;
        this.setState(prevState => ({
            ...prevState, search: {
                ...prevState.search, advanceSearch: advanceSearchState
            }
        }), () => {
            this.props.saveSearchParams(this.state.search);
            localStorage.setItem('searchParams', JSON.stringify(this.state.search));
        });
    }

    changeStatusForSearch(status, parentObject) {
        const stateClone = {...this.state.search[parentObject]};
        const result = setAllTo(status, stateClone);
        this.setState(prevState => ({
            ...prevState, search: {
                ...prevState.search, [parentObject]: result
            }
        }), () => {
            this.props.saveSearchParams(this.state.search);
            localStorage.setItem('searchParams', JSON.stringify(this.state.search))
        });
    }

    handleSearchCheckBox(e, path, parentObject) {
        let isChecked = e.target.checked;
        const stateClone = {...this.state.search[parentObject]};
        this.setState(prevState => ({
            ...prevState, search: {
                ...prevState.search, [parentObject]: updateStateVariableDynamic(stateClone, isChecked, path)
            }
        }), () => {
            this.props.saveSearchParams(this.state.search);
            localStorage.setItem('searchParams', JSON.stringify(this.state.search))
        });
    }

    openAdvanceSearchModal() {
        this.setState({
            search:
                {
                    // ...this.state.search,
                    // advanceSearch: OC.ADVANCESEARCH,
                    // additionalFilters: OC.ADITIONALFILTERS,
                    // userPreferences: OC.USERPREFERENCES,
                    ...this.props.searchParams
                }
        }, () => {

            this.setState({advanceSearchModal: true, advanceSearchAction: 'create'});
        });
    }

    closeAdvanceSearchModal() {
        this.setState({advanceSearchModal: false});
    }

    ordersPaginationData(page, filter, sort, pageSize) {
        this.setState({orderPage: page, filtered: filter, sorted: sort, pageSizeTotal: pageSize}, function () {
            var searchData = {
                search: {...this.state.search.advanceSearch},
                additional_filters: {...this.state.search.additionalFilters}
            };
            this.props.getOrdersPaginationCall(page, filter, sort, pageSize, searchData);
        });
    }

    filterSearchHandle(e) {
        e.persist();
        // console.log('e.key', e.keyCode);
        this.setState({
            'filtered': e.target.value
        }, () => {
            if (e.keyCode === 13) {

                // console.log('e.key one', e.keyCode);
                this.pagination.dataCall();
            }
        });
    }

    deleteConfirmOrder(orderId) {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <Confirmalertfordelete typeName="User" description="Are you sure to delete this order?"
                                           onClosePro={onClose} deleteType={() => this.props.deleteOrderCall(orderId)}/>
                )
            }
        })
    }


    paginationCall = (data) => {
        var searchData = {
            search: {...this.state.search.advanceSearch},
            additional_filters: {...this.state.search.additionalFilters}
        };
		console.log("sorted in ordersPaginationData", data.sort);
        console.log("searchData", searchData);
        return orderService.getOrdersPagination(data.page, this.state.filtered, data.sort, data.pageSize, searchData)
        // return this.props.getOrdersPaginationCall(data.page, this.state.filtered, data.sort, data.pageSize, searchData);
    }

    setOptions(parentObject, data) {
        this.setState(prevState => ({
            ...prevState, options: {
                ...prevState.options, [parentObject]: data
            }
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.advanceSearchModal && !this.state.advanceSearchModal) {
            // console.log('test one');
            this.setState({
                ...this.state,
                collapse: {...this.state.collapse, isOpenSaveSearch: false},
                advanceSearchAction: '',
                newSearch: {...this.state.newSearch, is_default: false}
            },)
        }
        //else {
        // if (this.props.defaultPreferences && !this.state.search.is_default) {
        //   // console.log('test uuuu');
        //   this.setState({ ...this.state, search : {...this.state.search, is_default : this.props.defaultPreferences.id === this.state.defaultSearchId ? true : false} })
        // }
        //}
        // if (!prevState.advanceSearchModal && this.state.advanceSearchModal) {
        //   this.setState({ ...this.state, search : {...this.state.search, is_default : this.props.defaultPreferences.id === this.state.defaultSearchId ? true : false} })
        // }
        if ((prevProps.deleteOrder !== this.props.deleteOrder) && !this.props.loadingSettings) {
            this.pagination.dataCall();
        }

        if (prevProps.defaultPreferences !== this.props.defaultPreferences) {
            // var tempSearch = cloneDeep(this.props.searchParams);
            // tempSearch['is_default'] = this.props.defaultPreferences.is_default;

            this.setState({
                // search: intersectionOfObjectsWithNewValue({
                //   advanceSearch: OC.ADVANCESEARCH,
                //   additionalFilters: OC.ADITIONALFILTERS,
                //   userPreferences: OC.USERPREFERENCES,
                // },
                //   this.props.defaultPreferences.preferences
                // ),
                // search: this.props.defaultPreferences.preferences,
                newSearch: {...this.state.newSearch, is_default: true},
                defaultSearchId: this.props.defaultPreferences.id
            }, () => {
                // this.pagination.dataCall();
            });
        }

        if ((this.props.states !== prevProps.states) && !this.props.loadingSettings && this.props.statesType === 'orderList') {
            this.setOptions('states', this.props.states);
        }
        //this.props.dashboard to check weather it is dashboard or not
        if (this.props.orderStatus !== prevProps.orderStatus && this.props.Dashboard) {
            this.setState(prevState => ({
                search: {
                    ...prevState.search,
                    advanceSearch: {
                        ...prevState.search.advanceSearch,
                        status_id: this.props.orderStatus.value
                    },
                }
            }))
        }

        if (this.props.orderStatuses !== prevProps.orderStatuses && this.props.Dashboard === undefined) {
            this.setState(prevState => ({
                search: {
                    ...prevState.search,
                    advanceSearch: {
                        ...prevState.search.advanceSearch,
                        status_id: this.props.orderStatuses.find(item => {
                            return item.label === 'Pending'
                        }).value
                    },
                }
            }))
        }

        // if (prevState.advanceSearchModal && !this.state.advanceSearchModal) {
        //   this.setState({
        //     search: this.props.searchParams
        //   }, () => {
        //     console.log("modal closed cdu", this.props.searchParams)
        //     console.log("modal closed cdu", this.state.search)
        //   })
        // }

        // if ( this.state.advanceSearchAction === 'create') {

        //   // console.log('advanceSearchAction');
        //   this.setState(prevState => ({
        //     newSearch: {
        //       ...prevState.newSearch,
        //       is_default: false,
        //     }
        //   }))

        // }
    }

    changeOrderStatus = (selected) => {
        this.setState(prevState => ({
            search: {
                ...prevState.search,
                advanceSearch: {
                    ...prevState.search.advanceSearch,
                    status_id: selected.value
                },
            },
        }), () => {
            this.pagination.dataCall();
        })
    }


    changeToDefault = () => {
        console.log('changeToDefault')
        this.setState(prevState => ({
            ...prevState,
            search: intersectionOfObjectsWithNewValue(
                {
                    advanceSearch: OC.ADVANCESEARCH,
                    additionalFilters: OC.ADITIONALFILTERS,
                    userPreferences: OC.USERPREFERENCES,
                },
                this.props.defaultPreferences.preferences
            ),
            defaultSearchId: this.props.defaultPreferences.id
        }));
    }
    setSearchName = (e) => {
        console.log('check box click');
        const {name, value} = e.target;
        const newSearchState = cloneDeep(this.state.newSearch);
        if (e.target.type === 'checkbox') {
            console.log('check box click 1');
            newSearchState[name] = e.target.checked;
        } else {
            newSearchState[name] = value;
        }
        this.setState({
            ...this.state, newSearch: newSearchState
        })
    }
    handleChangeSearch = (selectedOptionType, info) => {
        console.log('defaultPreferences 179', this.props.defaultPreferences);
        console.log('defaultPreferences 180', selectedOptionType.id);
        this.setState({
            ...this.state, search: intersectionOfObjectsWithNewValue({
                    advanceSearch: {...OC.ADVANCESEARCH},
                    additionalFilters: {...OC.ADITIONALFILTERS},
                    userPreferences: {...OC.USERPREFERENCES},
                },

                selectedOptionType.preferences
            ),
            defaultSearchId: selectedOptionType.id,
            newSearch: {
                ...this.state.newSearch,
                is_default: selectedOptionType.id === this.props.defaultPreferences.id ? true : false
            },
        }, () => {
            console.log('178', this.state.newSearch);
            this.setState({advanceSearchModal: true, advanceSearchAction: 'update'});
        });
    }

    getRowProps = (rowInfo) => {
        let data = {}
        if (rowInfo !== undefined) {
            const colors = {
                'Cancelled': 'red',
                'On Hold': 'orange',
                'Un Resolved': 'purple'
            }
            const status = rowInfo.status.title
            data = {
                color: colors[status]
            }
        }
        return data;
    }
    quickUrl = (record) => {
        //console.log(record)
        let match, result;
        match = document.domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
        if (match) {
            result = match[1]
            let domainSlugs = ['gtv', 'brt', 'mci', 'eti', 'itk', 'act', 'tech', 'aom'];
            if (domainSlugs.includes(result.split('.')[0])) {
                result = result.split('.').slice(1).join('.');
            }
        }
        let link = returnDynamicURL(record.accounts[0].domain_slug, record.username, record.password)
        return link
    }
    savedSearched = () => {
        const {defaultSearchId} = this.state;
        const {UserSearches} = this.props;
        return (
            <div style={{width: '35%'}} className="flexElem alignCenter spaceBetween mb-md">
                <div style={{'width': "calc(45% - 10px)"}} className="selectIndexFix">
                    <label className="container-check mb-none"> <strong>Select Search : </strong></label>

                    {
                        /*
                          <ReactSelect
                            value={orderStatuses.find(item => {
                              return item.value === advanceSearch.status_id
                            })}
                            onChange={this.changeOrderStatus}
                            options={orderStatuses}
                            placeholder="Change Order Status"
                            isMulti={false}
                          />

                        */
                    }
                </div>
                <div style={{'width': "calc(80% - 10px)"}} className="selectIndexFix">
                    <Select
                        value={
                            defaultSearchId ? {
                                id: returnColumnValueWithColumnMatch(UserSearches, 'id', [defaultSearchId], 'id'),
                                searchName: returnColumnValueWithColumnMatch(UserSearches, 'searchName', [defaultSearchId], 'id')
                            } : null
                        }

                        options={UserSearches}
                        onChange={this.handleChangeSearch}
                        getOptionLabel={(option) => option.searchName}
                        getOptionValue={(option) => option.id}
                        className="selectUser"
                        placeholder="Select Search"
                        isMulti={false}
                    />
                </div>
            </div>
        );
    }

    render() {
        const {options, collapse, defaultSearchId, advanceSearchAction, newSearch} = this.state;
        const {userPreferences, additionalFilters, advanceSearch/*, is_default*/} = this.state.search;
        const {agenciesOption, countries, mainProgramType/*, searchParams */, brands, publishableAgenciesOption} = this.props;
        if (hasRole(['gm admin'])) {
            brands.filter(item => {
                return Number(item.is_master) !== 1;
            });
        } 
        else {
            brands.filter(item => {
                return Number(item.is_master) !== 1;
            });
        }
        let columns = [
            {
                Header: "Orders Listing",
                headerClassName: 'headerPagination',
                Cell: () => {
                    return <div style={{overflow: 'auto'}}></div>
                },
                columns: [
                    {
                        Header: "Barcode",
                        accessor: "barcode",
                        width: 200,
                        className: "action-center",
                        Cell: ({value, original}) => {
                            return <Link target="_blank"
                                         to={'/Admin/' + (original.reorder ? original.reorder : original.id) + '/order-edit'}
                                         className="blue_link">{value}</Link>
                        },
                        excExp: true,
                    },
                    {
                        Header: "Group Name",
                        accessor: "group_name",
                        className: "action-center",
                        show: userPreferences.group_name,
                        excExp: userPreferences.group_name,
                    },

                    {
                        Header: "Agency Name",
                        accessor: "agency.name",
                        className: "action-center",
                        show: userPreferences.agency.name,
                        excExp: userPreferences.agency.name,
                        width: 140,
                    },
                    {
                        Header: "Group Type",
                        accessor: "agency_type.title",
                        show: userPreferences.agency_type.title,
                        excExp: userPreferences.agency_type.title,
                        className: "action-center",
                    },
                    {
                        Header: "Agency Sales Rep",
                        accessor: "agency_sales_rep.name",
                        className: "action-center",
                        show: userPreferences.agency_sales_rep,
                        excExp: userPreferences.agency_sales_rep,
                    },
                    {
                        Header: "GTV Sales Rep",
                        accessor: "gtv_sales_rep.name",
                        className: "action-center",
                        show: userPreferences.gtv_sales_rep,
                        excExp: userPreferences.gtv_sales_rep,
                    },
                    {
                        Header: "Group Leader Contact Name",
                        accessor: "group_leader_contact.name",
                        className: "action-center",
                        show: userPreferences.group_leader_contact.name,
                        excExp: userPreferences.group_leader_contact.name,
                    },
                    {
                        Header: "Group Leader Contact Phone",
                        accessor: "group_leader_contact.phone1",
                        Cell: ({value}) => (
                            <div>
                                {
                                    value &&
                                    value.replace(/-+$/, '')
                                }
                            </div>
                        ),
                        className: "action-center",
                        show: userPreferences.group_leader_contact.phone1,
                        excExp: userPreferences.group_leader_contact.phone1,
                    },
                    {
                        Header: "Group Leader Contact Email",
                        accessor: "group_leader_contact.email",
                        className: "action-center breakText",
                        show: userPreferences.group_leader_contact.email,
                        excExp: userPreferences.group_leader_contact.email,
                    },
                    {
                        Header: "Destinations",
                        accessor: "destinations",
                        className: "action-center",
                        show: userPreferences.destinations,
                        excExp: userPreferences.destinations,
                    },
                    {
                        Header: "Client Tour Number",
                        accessor: "client_tour_number",
                        className: "action-center",
                        show: userPreferences.client_tour_number,
                        excExp: userPreferences.client_tour_number,
                    },
                    {
                        Header: "Client Tour Code",
                        accessor: "client_tour_code",
                        className: "action-center",
                        show: userPreferences.client_tour_code,
                        excExp: userPreferences.client_tour_code,

                    },

                    {
                        Header: "Pre Trip Material Address Street",
                        accessor: "pre_trip_material_address.street_address_1",
                        className: "action-center",
                        show: userPreferences.pre_trip_material_address.street_address_1,
                        excExp: userPreferences.pre_trip_material_address.street_address_1,
                    },
                    {
                        Header: "Pre Trip Material Address City",
                        accessor: "pre_trip_material_address.city",
                        className: "action-center",
                        show: userPreferences.pre_trip_material_address.city,
                        excExp: userPreferences.pre_trip_material_address.city,
                    },
                    {
                        Header: "Pre Trip Material Address State",
                        accessor: "pre_trip_material_address.state.title",
                        Cell: ({value}) => (
                            <div>
                                {value}
                            </div>
                        ),
                        className: "action-center",
                        show: userPreferences.pre_trip_material_address.state,
                        excExp: userPreferences.pre_trip_material_address.state,
                    },
                    {
                        Header: "Pre Trip Material Address Zip",
                        accessor: "pre_trip_material_address.zipcode",
                        className: "action-center",
                        show: userPreferences.pre_trip_material_address.zipcode,
                        excExp: userPreferences.pre_trip_material_address.zipcode,
                    },
                    {
                        Header: "Video Shipping Address Street",
                        accessor: "video_shipping_address.street_address_1",
                        Cell: ({value}) => (
                            <div>
                                {value}
                            </div>
                        ),
                        className: "action-center",
                        show: userPreferences.video_shipping_address.street_address_1,
                        excExp: userPreferences.video_shipping_address.street_address_1,
                    },
                    {
                        Header: "Video Shipping Address City",
                        accessor: "video_shipping_address.city",
                        Cell: ({value}) => (
                            <div>
                                {value}
                            </div>
                        ),
                        className: "action-center",
                        show: userPreferences.video_shipping_address.city,
                        excExp: userPreferences.video_shipping_address.city,
                    },
                    {
                        Header: "Video Shipping Address State",
                        accessor: "video_shipping_address.state.title",
                        Cell: ({value}) => (
                            <div>
                                {value}
                            </div>
                        ),
                        className: "action-center",
                        show: userPreferences.video_shipping_address.state,
                        excExp: userPreferences.video_shipping_address.state,
                    },
                    {
                        Header: "Video Shipping Address Zip",
                        accessor: "video_shipping_address.zipcode",
                        Cell: ({value}) => (
                            <div>
                                {value}
                            </div>
                        ),
                        className: "action-center",
                        show: userPreferences.video_shipping_address.zipcode,
                        excExp: userPreferences.video_shipping_address.zipcode,
                    },

                    {
                        Header: "Departure Date",
                        accessor: "order_dates.departure_date.dateString",
                        className: "action-center",
                        show: userPreferences.order_dates.departure_date.value,
                        excExp: userPreferences.order_dates.departure_date.value,
                        id: "order_dates.departure_date.value"
                    },
                    {
                        Header: "Return Date",
                        accessor: "order_dates.return_date.dateString",
                        className: "action-center",
                        show: userPreferences.order_dates.return_date.value,
                        excExp: userPreferences.order_dates.return_date.value,
                        id: "order_dates.return_date.value"
                    },
                    {
                        Header: "Last Day At School",
                        accessor: "order_dates.last_day_of_school.dateString",
                        className: "action-center",
                        show: userPreferences.order_dates.last_day_of_school.value,
                        excExp: userPreferences.order_dates.last_day_of_school.value,
                        id: "order_dates.last_day_of_school.value"
                    },
                    {
                        Header: "Pre Trip Package Need Date",
                        accessor: "order_dates.pre_trip_package_need_date.dateString",
                        className: "action-center",
                        show: userPreferences.order_dates.pre_trip_package_need_date.value,
                        excExp: userPreferences.order_dates.pre_trip_package_need_date.value,
                        id: "order_dates.pre_trip_package_need_date.value"
                    },
                    {
                        Header: "Receive Date",
                        accessor: "order_dates.receive_date.dateString",
                        className: "action-center",
                        show: userPreferences.order_dates.receive_date.value,
                        excExp: userPreferences.order_dates.receive_date.value,
                        id: "order_dates.receive_date.value"
                    },
                    {
                        Header: "Video Special Need Date",
                        accessor: "order_dates.video_special_need_date.dateString",
                        className: "action-center",
                        show: userPreferences.order_dates.video_special_need_date.value,
                        excExp: userPreferences.order_dates.video_special_need_date.value,
                        id: "order_dates.video_special_need_date.value"
                    },
                    {
                        Header: "Upload Cut Off Date",
                        accessor: "order_dates.upload_cut_off_date.dateString",
                        className: "action-center",
                        show: userPreferences.order_dates.upload_cut_off_date.value,
                        excExp: userPreferences.order_dates.upload_cut_off_date.value,
                        id: "order_dates.upload_cut_off_date.value"
                    },
                    {
                        Header: "Photo Admin Username",
                        accessor: "photo_admin_username",
                        className: "action-center",
                        show: userPreferences.photo_admin_username,
                        excExp: userPreferences.photo_admin_username,
                    },
                    {
                        Header: "Photo Admin Password",
                        accessor: "photo_admin_password",
                        className: "action-center",
                        show: userPreferences.photo_admin_password,
                        excExp: userPreferences.photo_admin_password
                    },
                    {
                        Header: "Order Total",
                        id: 'order_totals',
                        accessor: "order_totals.grand_total.value",
                        Cell: ({value}) => {
                            return (
                                <div>
                                    {returnDollars(value)}
                                </div>
                            )
                        },
                        className: "action-center",
                        show: userPreferences.order_totals.total,
                        excExp: userPreferences.order_totals.total,
                    },

                    {
                        Header: "Program Type",
                        accessor: "program_type.title",
                        className: "action-center",
                        show: userPreferences.program_type.title,
                        excExp: userPreferences.program_type.title
                    },
                    {
                        Header: "Inventory Assignment",
                        accessor: "orderShippingItemsString",
                        className: "action-center",
                        show: userPreferences.inventory_assignment,
                        excExp: userPreferences.inventory_assignment,
                        sortable: false,
                    },
                    {
                        Header: "Going On The Trip",
                        accessor: "going_on_trip_count",
                        className: "action-center",
                        show: userPreferences.going_on_trip_count,
                        excExp: userPreferences.going_on_trip_count,
                    },

                    {
                        Header: "Following From Home",
                        accessor: "following_from_home_count",
                        className: "action-center",
                        show: userPreferences.following_from_home_count,
                        excExp: userPreferences.following_from_home_count,
                    },
                    {
                        Header: "Created Date",
                        accessor: "createdAtString",
                        className: "action-center",
                        show: userPreferences.created_at,
                        excExp: userPreferences.created_at,
                    },

                    {
                        Header: "Pre Trip Materials Tracking",
                        accessor: "orderShippingCameraTrack",
                        className: "action-center",
                        show: userPreferences.order_shipping_camera.tracking_number,
                        excExp: userPreferences.order_shipping_camera.tracking_number,
                        sortable: false,
                    },

                    {
                        Header: "Uploaded Photos Submitted Date",
                        accessor: "settings.photos_submitted_for_video_production.updated_atString",
                        className: "action-center",
                        show: userPreferences.settings.photos_submitted_for_video_production.value,
                        excExp: userPreferences.settings.photos_submitted_for_video_production.value,
                    },

                    {
                        Header: "Production Finish Date",
                        accessor: "settings.finalize_by_qa.updated_atStringQA",
                        Cell: ({value, original}) => (
                            parseInt(original.settings.finalize_by_qa.value) ? original.settings.finalize_by_qa.updated_atString : ''
                        ),
                        className: "action-center",
                        show: userPreferences.settings.finalize_by_qa.value,
                        excExp: userPreferences.settings.finalize_by_qa.value,
                        id: "order_dates.production_complete_date.value"
                    },


                    {
                        Header: "Number Of Videos",
                        accessor: "numberOfVideos",
                        className: "action-center",
                        show: userPreferences.numberOfVideos,
                        excExp: userPreferences.numberOfVideos,
                    },

                    {
                        Header: "Number Of Mozaix",
                        accessor: "numberOfMozaix",
                        className: "action-center",
                        show: userPreferences.numberOfMozaix,
                        excExp: userPreferences.numberOfMozaix,
                    },

                    {
                        Header: "Traveler Quick Login",
                        accessor: "id",
                        Cell: ({value, original}) => (
                            <div>
                                {
                                    <button type="button"
                                            onClick={() => this.props.loginCall(original.username, original.password)}>Quick
                                        Login</button>
                                }
                                <OverlayTrigger placement='top'
                                                overlay={<Popover id='copyLink'>Copy to clipboard</Popover>}>
                    <span>
                        <CopyToClipboard text={
                            this.quickUrl(original)
                        }
                        >
                            <button className="orderDetailsAction copyColorAction" type="button">
                                <span className="">&nbsp;</span>
                            </button>
                        </CopyToClipboard>
                    </span>
                                </OverlayTrigger>
                            </div>
                        ),
                        className: "action-center",
                        show: userPreferences.quick_login,
                        excExp: userPreferences.quick_login,
                        csvMod: true
                    },

                    {
                        Header: "Actual Video Ship Date",
                        accessor: "order_shipping_video.created_at",
                        className: "action-center",
                        show: userPreferences.order_shipping_video.created_at,
                        excExp: userPreferences.order_shipping_video.created_at,
                        sortable: false,
                    },

                    {
                        Header: "Number of Uploaded Photos",
                        accessor: "original_images_count",
                        className: "action-center",
                        show: userPreferences.originalImages,
                        excExp: userPreferences.originalImages,
                    },

                    {
                        Header: "Full Video Streaming Count",
                        accessor: "full_video_view_count",
                        className: "action-center",
                        show: userPreferences.settings.full_video_view_count.value,
                        excExp: userPreferences.settings.full_video_view_count.value,
                        sortable: false,
                    },

                    {
                        Header: "Full Video Download Count",
                        accessor: "full_video_download_count",
                        className: "action-center",
                        show: userPreferences.settings.full_video_download_count.value,
                        excExp: userPreferences.settings.full_video_download_count.value,
                        sortable: false,
                    },

                    {
                        Header: "Highlight Video Streaming Count",
                        accessor: "highlight_video_view_count",
                        className: "action-center",
                        show: userPreferences.settings.highlight_video_view_count.value,
                        excExp: userPreferences.settings.highlight_video_view_count.value,
                        sortable: false,
                    },

                    {
                        Header: "Highlight Video Download Count",
                        accessor: "highlight_video_download_count",
                        className: "action-center",
                        show: userPreferences.settings.highlight_video_download_count.value,
                        excExp: userPreferences.settings.highlight_video_download_count.value,
                        sortable: false,
                    },

                    {
                        Header: "Online Options Page Submitted",
                        accessor: "settings.online_options_page_submitted.value",
                        Cell: ({value}) => (
                            <div>
                                {
                                    value === '1' ? 'Submitted' : 'Not Submitted'

                                }
                            </div>
                        ),
                        className: "action-center",
                        show: userPreferences.settings.online_options_page_submitted.value,
                        excExp: userPreferences.settings.online_options_page_submitted.value,
                    },

                    {
                        Header: "Projected pre-trip materials ship date ",
                        accessor: "orderShippingCameraShippedDateCSV",
                        Cell: ({row, original}) => (
                            <div>
                                {
                                    original.orderShippingCameraFlag ?
                                        <strong>{original.orderShippingCameraShippedDate}</strong> : original.orderShippingCameraShippedDate
                                }
                            </div>
                        ),
                        className: "action-center",
                        show: userPreferences.order_shipping_camera.shipped_date,
                        excExp: userPreferences.order_shipping_camera.shipped_date,
                        sortable: false,
                    },
                    {
                        Header: "Edit/produced by ",
                        accessor: "edit_produced_by",
                        Cell: ({ value, original }) =>(
                            <div>
                                {
                                    <span>{(original.editor ? original.editor.name : '-- ') + "/" + (original.producer ? original.producer.name : ' --')}</span>
            
                                }
                            </div>
                        ),
                        className: "action-center",
                        show: userPreferences.edit_produced_by,
                        excExp: userPreferences.edit_produced_by,
                        sortable: false,
                    },

                    {
                        Header: "Production status ",
                        accessor: "production_status",
                        Cell: ({ row, original }) => (
							<div>
								{
									TF.productionStatus(row._original.settings, row._original.status, row._original.csr_steps, row._original.on_hold_last_comment && row._original.on_hold_last_comment, row._original.cancel_last_comment && row._original.cancel_last_comment, row._original.orderDates, row._original.shippingDetails, 'left')

								}
							</div>
						),
                        className: "action-center",
                        show: userPreferences.production_status,
                        excExp: userPreferences.production_status,
                        sortable: false,
                    },
                    // {
                    //     Header: "Actions",
                    //     accessor: "id",
                    //     Cell: ({row, original}) => (
                    //         <div>
                    //             <OverlayTrigger placement="bottom" overlay={<Popover
                    //                 id="tooltip">{hasPermission('order-edit') ? 'Edit Order Details' : adminLabels.DONT_ALLOW}</Popover>}>
                    //                 <span>
                    //                     {
                    //                         hasPermission('order-edit') &&
                    //                         <Link className="orderDetailsAction editIcon"
                    //                             to={'/Admin/' + row.id + '/order-edit'}>&nbsp;</Link>

                    //                     }
                    //                     {
                    //                         !hasPermission('order-edit') &&
                    //                         <button type='button' className="orderDetailsAction editIcon button_disabled">&nbsp;</button>
                    //                     }
                    //                 </span>
                    //             </OverlayTrigger>

                    //             {
                    //                 original.status.title === 'Cancelled' &&
                    //                 <OverlayTrigger placement="bottom" overlay={<Popover
                    //                     id="tooltip">{hasPermission('order-delete') ? 'Order Delete' : adminLabels.DONT_ALLOW}</Popover>}>
                    //                     <span>
                    //                     {
                    //                         hasPermission('order-delete') &&
                    //                         <button type='button' className="deleteIcon orderDetailsAction"
                    //                                 onClick={() => this.deleteConfirmOrder(row.id)}>&nbsp;</button>


                    //                     }
                    //                         {
                    //                             !hasPermission('order-delete') &&
                    //                             <button type='button'
                    //                                     className="orderDetailsAction deleteIcon button_disabled">&nbsp;</button>
                    //                         }
                    //                     </span>
                    //                 </OverlayTrigger>
                    //             }
                    //         </div>
                    //     ),
                    //     className: "action-center",
                    //     sortable: false,
                    // },
                ]
            }
        ];
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                ctTableResponsive
                                content={
                                    <div>
                                        <Grid fluid>
                                            <div className="secHeading">Orders List</div>


                                        </Grid>
                                        {
                                            userPreferences &&
                                            <Grid fluid>
                                                <Row>
                                                    <Col md={12}>

                                                    </Col>

                                                    <Col md={12} className="mt-sm">
                                                        {
                                                            defaultSearchId &&
                                                            <OrderListPagination
                                                                ref={(p) => this.pagination = p}
                                                                showPagination={false}
                                                                columns={columns}
                                                                pageSize={100}
                                                                getDataCall={this.paginationCall}
                                                                filterView={false}
                                                                upperContent={
                                                                    <div className="flexElem flexResponsive"
                                                                         style={{"justifyContent": 'space-between'}}>
                                                                        {
                                                                            this.props.Dashboard === undefined &&
                                                                            <div className="flexElem flexResponsive">
                                                                                <div>
                                                                                    <div>
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            trigger={['hover']}
                                                                                            overlay={<Popover
                                                                                                id="tooltip">Search</Popover>}>
                                                                                            <Button type='button'
                                                                                                    className="backButton pt-sm no_radius btn-block pb-sm primary mt-none btn btn btn-info "
                                                                                                    onClick={this.openAdvanceSearchModal}><i
                                                                                                className="fa fa-plus"/>Search</Button>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ml-md marginLeft991">
                                                                                    <div className="form-group">
                                                                                        {hasPermission('order-create') &&
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            overlay={<Popover
                                                                                                id="tooltip">Add
                                                                                                Order</Popover>}>
                                                                                            <Link
                                                                                                to="/Admin/order-create"
                                                                                                className="backButton pt-sm no_radius pb-sm primary mt-none btn btn-info "><i
                                                                                                className="fa fa-plus"/>Order</Link>
                                                                                        </OverlayTrigger>
                                                                                        }
                                                                                        {!hasPermission('order-create') &&
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            overlay={<Popover
                                                                                                id="tooltip">{adminLabels.DONT_ALLOW}</Popover>}>
                                                                                            <button
                                                                                                to="/Admin/order-create"
                                                                                                className="button_disabled backButton pt-sm no_radius pb-sm primary mt-none btn btn-info ">
                                                                                                <i className="fa fa-plus"/>Order
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.savedSearched()
                                                                        }

                                                                        <div className="custom-search-input"
                                                                             style={{'width': '30%'}}>
                                                                            <FormInputs
                                                                                ncols={["col-md-12"]}
                                                                                // onChange={this.filterSearchHandle}
                                                                                // onKeyDown={this.filterSearchHandle}
                                                                                proprieties={[
                                                                                    {
                                                                                        type: "text",
                                                                                        bsClass: "form-control",
                                                                                        placeholder: "Search Orders",
                                                                                        // onChange: this.filterSearchHandle,
                                                                                        onKeyDown: this.filterSearchHandle,
                                                                                        name: "filter"
                                                                                    }
                                                                                ]}
                                                                            />
                                                                        </div>

                                                                        <div
                                                                            className="form-group orderStatus flexElem">
                                                                            <div>
                                                                                Orders in
                                                                            </div>
                                                                            <div className="cancelled">
                                                                                RED
                                                                            </div>
                                                                            <div>
                                                                                = Cancelled
                                                                            </div>
                                                                            <div className="onHold">
                                                                                ORANGE
                                                                            </div>
                                                                            <div>
                                                                                = On Hold
                                                                            </div>

                                                                            <div className="unresolved">
                                                                                PURPLE
                                                                            </div>
                                                                            <div>
                                                                                = Unresolved
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                downloadData={hasRole('Remote Editor/Producer') ? false : true}
                                                                downloadFileName={'Orders'}
                                                                // defaultSorted={[
                                                                //     { id: 'order_dates.departure_date.value', desc: false }
                                                                // ]}
                                                                // lowerContent = {null}
                                                                // filterPlaceHolder = {'Orders'}
                                                                getRowProps={this.getRowProps}
                                                                showAllToggle={true}
                                                                showTotal={true}
                                                            />
                                                        }
                                                    </Col>
                                                </Row>
                                            </Grid>
                                        }
                                    </div>
                                }/>
                        </Col>
                    </Row>
                </Grid>
                <Modal backdrop={'static'} show={this.state.advanceSearchModal} onHide={this.closeAdvanceSearchModal}
                       aria-labelledby="ModalHeader" className="advanceSearch">
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Search</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="content">

                            {
                                this.savedSearched()
                            }
                            <AdvanceSearchForm
                                componentPro="additionalFilters"
                                advanceSearch={advanceSearch}
                                handleAdvanceSearch={this.handleAdvanceSearch}
                                handleChangeAdvanceSearchDropDown={this.handleChangeAdvanceSearchDropDown}
                                handleChangeBrand={this.handleChangeBrand}
                                onChangsAdvanceSearchDate={this.onChangsAdvanceSearchDate}
                                clearAdvanceSearchForm={this.clearAdvanceSearchForm}
                                options={
                                    {
                                        'agencies': agenciesOption,
                                        'countries': countries,
                                        'states': options.states,
                                        'mainProgramType': mainProgramType,
                                        'brands': brands
                                    }
                                }
                                performSearch={this.performSearch}
                                isOpen={collapse.isOpenAdvanceSearch}
                                collapseHandle={this.collapseHandle}
                            />

                            <AdditionalSearch
                                additionalFilters={additionalFilters}
                                componentPro="additionalFilters"
                                handleSearchCheckBoxPro={this.handleSearchCheckBox}
                                changeStatusForSearch={this.changeStatusForSearch}
                                performSearch={this.performSearch}
                                isOpen={collapse.isOpenAdvanceFilters}
                                collapseHandle={this.collapseHandle}
                            />
                            <UserPreferences
                                closeModalUserPro={this.closeAdvanceSearchModal}
                                handleSearchCheckBoxPro={this.handleSearchCheckBox}
                                componentPro="advanceSearch"
                                userPreferences={userPreferences}
                                changeStatusForSearch={this.changeStatusForSearch}
                                // submitUserPreferences={this.submitUserPreferences}
                                isOpen={collapse.isOpenUserPreferences}
                                performSearch={this.performSearch}
                                collapseHandle={this.collapseHandle}
                                changeToDefault={this.changeToDefault}
                                hide={() => {
                                    this.setState({advanceSearchModal: false})
                                }}
                            />
                            {

                                <React.Fragment>
                                    <div className="flexElem alignCenter form-group justifyCenter">
                                        {
                                            collapse.isOpenSaveSearch &&
                                            <div className='m-sm'>
                                                <input type="text" className="form-control" name="searchName"
                                                       onChange={this.setSearchName}/>
                                            </div>
                                        }
                                        <div className='m-sm'>
                                            <label className="container-check mb-none"> Is default <input
                                                type="checkbox" name="is_default" className="checkbox"
                                                onChange={this.setSearchName} checked={newSearch.is_default}/><span
                                                className="checkmark"></span></label>
                                        </div>
                                        {
                                            collapse.isOpenSaveSearch &&
                                            <div className='m-sm'>
                                                <button onClick={() => this.submitUserPreferences('')}
                                                        className="backButton pt-sm no_radius pb-sm success btn btn-info ">Save
                                                    Search
                                                </button>
                                            </div>
                                        }

                                    </div>
                                </React.Fragment>
                            }
                            <button className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm"
                                    onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            collapse: {
                                                ...this.state.collapse,
                                                isOpenSaveSearch: !this.state.collapse.isOpenSaveSearch
                                            }
                                        })
                                    }}>{collapse.isOpenSaveSearch ? 'Cancel' : 'Create New Search'}</button>
                            {
                                (advanceSearchAction === 'update' && !collapse.isOpenSaveSearch) &&
                                <button
                                    className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm"
                                    onClick={() => this.submitUserPreferences(defaultSearchId)}>Update</button>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    };
}

const mapDispatchToProps = dispatch => {
    return ({

        deleteOrderCall: (id) => {
            dispatch(deleteOrder(id))
        },
        getUserPreferencesCall: () => {
           return dispatch(getUserPreferences())
        },
        storeOrUpdateUserPreferencesCall: (preferencesData, searchId) => {
            return dispatch(storeOrUpdateUserPreferences(preferencesData, searchId))
        },
        getAgenciesCall: () => {
            dispatch(getAllAgencies())
        },
        getAllAgenciesPublishableCall: () => {
            dispatch(getAllAgenciesPublishable())
        },
        getCountriesCall: () => {
            dispatch(getCountries())
        },
        getStateCall: (countryId, type) => {
            dispatch(getStates(countryId, type))
        },
        getMainProgramTypeCall: () => {
            dispatch(getMainProgramType())
        },
        getOrderStatuses: () => {
            dispatch(getOrderStatuses())
        },
        getDefaultPreferenceCall: () => {
            dispatch(getDefaultPreference())
        },
        storeAdvanceSearchCall: (data) => {
            dispatch(storeAdvanceSearch(data))
        },
        loginCall: (email, password) => {
            dispatch(login(email, password))
        },
        saveSearchParams: (data) => {
           return dispatch(saveSearchParams(data))
        },
        getOrdersPaginationCall: (page, filter, sort, pageSize, searchData) => {
            return dispatch(getOrdersPagination(page, filter, sort, pageSize, searchData))
        },
        getBrandsCall: () => { dispatch(getBrands())
        },
    });
};

function mapStateToProps(state) {
    const {
        loadingSettings,
        UserSearches,
        countries,
        states,
        statesType,
        mainProgramType,
        orderStatuses,
        defaultPreferences
    } = state.settingsReducer;
    const {deleteOrder, total_orders, searchParams} = state.ordersReducer;
    const {loadingAgency, agenciesOption, publishableAgenciesOption} = state.agenciesReducer;
    const { brands } = state.accountReducer;
    return {
        loadingSettings, UserSearches, countries, states, statesType, mainProgramType,
        deleteOrder, total_orders,
        loadingAgency, agenciesOption, orderStatuses, defaultPreferences,
        searchParams,
        brands,
        publishableAgenciesOption
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
